<template>
  <div class="">
  <div class="imports">
    <!-- <div>导入导入</div> -->
      <div class="con">
          <!-- <div class="tit">
              <div><span @click="dianji_fanhui">返回</span>{{tit_name}}</div>
              <div @click="dianji_rzhi">操作日志<i></i></div>
          </div> -->
          <div class="box">
              <ul>
                  <li>
                      <span>1</span>{{con_text1}}<p @click="moban_xiazai">下载</p>
                  </li>
                  <li style="margin-top:42px;">
                      <span>2</span>{{con_text2}}
                      <el-upload
                        class="upload-demo"
                        ref="upload"
                        action=''
                        :file-list="fileList"
                        :multiple="false"
                        :auto-upload="false">
                        <el-button style="background: #ff2525;border:none;margin-left:15px;margin-right:30px; padding:6px 4px" slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                  </li>
              </ul>
              <p class="jianyi">单次导入条数不超过10000条</p>
              <p class="p1">{{foot_text1}}</p>
          </div>
      </div>
      <jindutiao ref="jindu_tiao"></jindutiao>
    </div>
  </div>
</template>

<script>
import jindutiao from './jindutiao/jindutiao'
import { import_staff_ai } from '@api/user'
import axios from 'axios'
export default {
  name: 'Imports',
  props:['piliangdaoru'],
  data() {
    return {
      fileList: [],
      tit_name: '',
      con_text1: '',
      con_text2: '',
      foot_text1: ''
    }
  },
  // components: { heade, foote, jindutiao },
  components: { jindutiao },
  computed:{
    ent_id(){
      return this.$store.state.ent_id
    }
  },
  created() {
        if (this.piliangdaoru == '部门') {
        this.tit_name = '批量导入部门和人员'
        this.con_text1 = '下载添加部门员工名单模板，批量添加部门和员工'
        this.con_text2 = '上传填写好的员工信息表'
        this.foot_text1 = '法律声明：企业员工和部门导入服务由餐亿本研发并经授权企业管理员选择开通、使用、管理，相关数据存储于餐亿本专属云存储空间并归属企业所有。餐亿本安全技术已通过相关机构认证，请放心使用。'
      } else if (this.piliangdaoru == '科目') {
        this.tit_name = '批量导入核算科目'
        this.con_text1 = '下载添加核算科目，批量添加核算科目'
        this.con_text2 = '上传填写好的核算科目'
        this.foot_text1 = '法律声明：企业核算科目导入服务由餐亿本研发并经授权企业管理员选择开通、使用、管理，相关数据存储于餐亿本专属云存储空间并归属企业所有。餐亿本安全技术已通过相关机构认证，请放心使用。'
      } else if (this.piliangdaoru == '菜品') {
        this.tit_name = '批量导入菜品'
        this.con_text1 = '下载添加菜品，批量添加菜品'
        this.con_text2 = '上传填写好的菜品'
        this.foot_text1 = '法律声明：企业菜品导入服务由餐亿本研发并经授权企业管理员选择开通、使用、管理，相关数据存储于餐亿本专属云存储空间并归属企业所有。餐亿本安全技术已通过相关机构认证，请放心使用。'
      }
  },

  methods: {


      // --------------------------------------

      // --------------------------------------

    // 上传文件
    uploadFile() {
      console.log('上传文件+上传文件', this.$refs.upload.uploadFiles)
      const date = this.$refs.upload.uploadFiles
      const list = []
      const shibai = []
      const fasong = []
      for (let i = 0; i < date.length; i++) {
        list.push(date[i].raw)
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
          shibai.push(list[i])
        } else {
          const fileObj = list[i]
          const form = new FormData()// FormData 对象
          form.append('files', fileObj)// 文件对象  'upload'是后台接收的参数名
          form.append('ent_id', this.ent_id)
          form.append('user_id', sessionStorage.getItem('user_id'))
          // console.log(' 文件对象upload是后台接收的参数名', fileObj)
          console.log(form,'--85555');
          // console.log(fileObj.name,'416464646654');
          // sessionStorage.setItem('pc_mlbb_shangchuan_chenggong', fileObj.name)
          if (this.piliangdaoru == '部门') {
            const config = {
                      onUploadProgress: (progress) => {
                        // 格式化成百分数
                        var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
                // this.text = complete + '%'
                         console.log(complete + '%')
                      }
                    }
                    console.log(form);
              axios({
                  method: 'post',
                  url: 'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/import_staff_ai',
                  data:form,
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(res=>{
                  let code = res.data.code
                 
                 if(code=== '10143' ){
                        this.$message({
                          message: '导入成功',
                          type: 'success'
                        })
                    }else if(code === '10144') {
                      this.$message({
                        message: '导入失败，请重新导入',
                        type: 'error'
                      })
                    } else if (code === '11800') {
                      this.$message({
                        message: '导入文件的sheet页名不对',
                        type: 'error'
                      })
                    } else if (code === '11900') {
                      this.$message({
                        message: '导入文件的模板格式不对',
                        type: 'error'
                      })
                    }
                })
          } else if (this.piliangdaoru == '科目') {
            axios({
              method: 'post',
              url: '/import_subject_ai',
              type: 'post',
              data: form,
              onUploadProgress: progressEvent => {
                var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
                // this.text = complete + '%'
                console.log(complete + '%')
                // this.$refs.jindu_tiao.huoqu(true,complete)
              }
            }).then(res => {
              console.log(res)
              // this.$refs.jindu_tiao.huoqu(false,0)
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.data.body))
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju2', '科目')
              if (res.data.code == 10175) {
                this.$message({
                  message: '导入成功',
                  type: 'success'
                })
                this.$router.push('/feedbackpage')
              } else if (res.data.code == 10176) {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              }
            })
            // $.ajax({
            //     url:'https://www.mlcbr.com/hwb_client/V1.0.0/import_subject_ai',//服务器
            //     // url:'http://www.meilibaobiao.com:8080/hwb_client/V1.0.0/import_subject_ai',//测试服务器
            //     type: 'post',
            //     data: form,
            //     processData: false,
            //     contentType: false,
            // }).then(res=>{
            //     console.log(res)
            //     sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju",  JSON.stringify(res.body));
            //     sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju2", '科目');
            //     if(res.code==10175){
            //         this.$message({
            //             message: '导入成功',
            //             type: 'success'
            //         });
            //         this.$router.push('/feedbackpage')
            //     }else if(res.code==10176){
            //         this.$message({
            //             message: '导入失败，请重新导入',
            //             type: 'error'
            //         });
            //     }
            // })
          } else if (this.piliangdaoru == '菜品') {
      
               axios({
                  method: 'post',
                  url: 'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/import_prod_ai',
                  data:form,
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(res=>{
              
                  let code = res.data.code
                     
                 if(code=== '10133' ){
                        this.$message({
                          message: '导入成功',
                          type: 'success'
                        })
                    }else if(code === '10144') {
                      this.$message({
                        message: '导入失败，请重新导入',
                        type: 'error'
                      })
                    } else if (code === '11800') {
                      this.$message({
                        message: '导入文件的sheet页名不对',
                        type: 'error'
                      })
                    } else if (code === '11900') {
                      this.$message({
                        message: '导入文件的模板格式不对',
                        type: 'error'
                      })
                    } else if(code ==='10134'){
                       this.$message({
                        message: '导入失败，请重新导入',
                        type: 'error'
                      })
                    }
                })
            // axios({
            //   method: 'post',
            //   url: '/import_prod_ai', form.append('user_id', sessionStorage.getItem('user_id'))
            //   type: 'post',
            //   data: form,
            //   onUploadProgress: progressEvent => {
            //     var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
            //     // this.text = complete + '%'
            //     console.log(complete + '%')
            //     // this.$refs.jindu_tiao.huoqu(true,complete)
            //   }
            // }).then(res => {
            //   console.log(res)
            //   // this.$refs.jindu_tiao.huoqu(false,0)
            //   // setTimeout(() => {
            //   // sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.data.body))
            //   // sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju2', '产品')
            //   if (res.data.code == 10133) {
            //     this.$message({
            //       message: '导入成功',
            //       type: 'success'
            //     })
            //     console.log(res)
            //     this.$router.push('/feedbackpage')
            //   } else if (res.data.code == 10134) {
            //     this.$message({
            //       message: '导入失败，请重新导入',
            //       type: 'error'
            //     })
            //   } else if (res.data.code == 11800) {
            //     this.$message({
            //       message: '导入文件的sheet页名不对',
            //       type: 'error'
            //     })
            //   } else if (res.data.code == 11900) {
            //     this.$message({
            //       message: '导入文件的模板格式不对',
            //       type: 'error'
            //     })
            //   }
            //   // }, 1000)
            // })
            // $.ajax({
            //     url:'https://www.mlcbr.com/hwb_client/V1.0.0/import_prod_ai',//服务器
            //     // url:'http://192.168.3.5:8080/hwb_client/V1.0.0/import_prod_ai',//cs
            //     type: 'post',
            //     data: form,
            //     processData: false,
            //     contentType: false,
            // }).then(res=>{
            //     console.log(res)
            //     sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju", JSON.stringify(res.body));
            //     sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju2", '产品');
            //     if(res.code==10133){
            //         this.$message({
            //             message: '导入成功',
            //             type: 'success'
            //         });
            //         this.$router.push('/feedbackpage')
            //     }else if(res.code==10134){
            //         this.$message({
            //             message: '导入失败，请重新导入',
            //             type: 'error'
            //         });
            //     }
            // })
          }
        }
      }
    },
    //   下载多个文件
    multiDownLoad(val) {
      for (let i = 0; i < val.length; i++) {
        // 创建一个新的dom节点
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.style.height = 0
        iframe.src = val[i]
        document.body.appendChild(iframe)
        setTimeout(res => {
          iframe.remove()
        }, 5 * 60 * 1000
        )
      }
    },
    // 模板下载
    moban_xiazai() {
      console.log(this.piliangdaoru);
      if (this.piliangdaoru == '部门') {
        const data = ['https://www.mlcbr.com/template/餐亿本-员工导入模板.xlsx']
        this.multiDownLoad(data)
      } else if (this.piliangdaoru == '科目') {
        const data = ['https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/template/核算科目模板.xlsx']
        this.multiDownLoad(data)
      } else if (this.piliangdaoru == '菜品') {
        console.log('菜品导入前的模板下载');
        const data = ['https://www.mlcbr.com/template/餐亿本-菜品导入模板.xlsx']
        this.multiDownLoad(data)
      }
    },
    // 点击日志
    dianji_rzhi() {
      const date = {
        name: '',
        cuowu: false
      }
      if (this.piliangdaoru == '部门') {
        date.name = '部门'
      } else if (this.piliangdaoru == '科目') {
        date.name = '科目'
      } else if (this.piliangdaoru == '菜品') {
        date.name = '菜品'
      }
      sessionStorage.setItem('pc_mlbb_bumenrzhi', JSON.stringify(date))
      this.$router.push('/Calendara')
    },
    // 点击返回
    dianji_fanhui() {
      console.log('点击返回点击返回点击返回shdgajhdgjasdgajd')
      window.history.go(-1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.imports {
    background:#E7E8EB;
    min-height: 100%;
    font-size: 16px;


    .con{
        width: 100%;

        background:#E7E8EB;
        margin: 0 auto;
        min-height: 300px;
        // .tit{
        //     height:80px;
        //     background:#f3f3f3;
        //     display: flex;
        //     align-items: center;
        //     justify-content: space-between;
        //     padding: 0 54px;
        //     // p{
        //     //     font-size: 18px;
        //     //     color:#1A2533;
        //     // }
        //     div{
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         &:nth-child(1){
        //             font-size: 18px;
        //             color:#1A2533;
        //             span{
        //                 padding: 5px 10px;
        //                 border:0.01rem solid #ccc;
        //                 border-radius: 3px;
        //                 background: #fff;
        //                 margin-right: 20px;
        //                 cursor: pointer;
        //             }
        //         }
        //         &:nth-child(2){
        //             display: flex;
        //             align-items: center;
        //             font-size: 14px;
        //             color:#979AA1;
        //             cursor:pointer;
        //             i{
        //                 display: block;
        //                 height:25px;
        //                 width:28px;
        //                 background: url(~@img/import/rzhi_no.png) no-repeat;
        //                 background-position:center center;
        //                 margin-left: 10px;
        //             }
        //             &:hover i{
        //                 background: url(~@img/import/rzhi_ok.png) no-repeat;
        //                 background-position:center center;
        //             }
        //         }
        //     }
        // }
        .box{
            width:100%;
            margin:0 auto;
            background: #fff;
            min-height: 300px;
            margin-bottom: 45px;
            ul{
                padding: 95px 60px 46px 60px;
                li{
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color:#4C4A4D;
                    span{
                        display: block;
                        height:35px;
                        width:35px;
                        margin-right: 18px;
                        border:1px solid #979797;
                        font-size: 20px;
                        color:#888888;
                        text-align: center;
                        line-height: 35px;
                        border-radius: 50%;
                    }
                    p{
                        font-size: 14px;
                        color:#fff;
                        padding: 5px 12px;
                        background: #ff2525;
                        margin-left: 15px;
                        border-radius: 2px;
                        cursor:pointer;
                    }
                    a{
                        font-size: 14px;
                        color:#fff;
                        padding: 5px 12px;
                        background: #ff2525;
                        margin-left: 15px;
                        border-radius: 2px;
                        cursor:pointer;
                    }
                    .upload-demo{
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .jianyi{
                padding: 0 60px;
                color: red;
            }
            .p1{
                font-size: 12px;
                color:#A9A9A9;
                padding: 0 60px;
            }
            .shangchuan{
                width:154px;
                height:38px;
                border-radius: 2px;
                border:1px solid #888888;
                line-height: 38px;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                color:#888888;
                margin: 0 auto;
                margin-top: 61px;
                cursor:pointer;
            }
        }
    }
    @media screen and (max-width: 1200px) {
      .con {
        width: 1200px;
      }
    }
}
</style>
