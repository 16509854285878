<template>
    <div class="jindu_tiao" v-show="zhanshi">
        <div class="jindu_tiao_box">
            <p><i class="el-icon-loading"></i></p>
            <p>正在上传请稍后……</p>
            <!-- <el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" :color="customColors"></el-progress> -->
        </div>

    </div>
</template>
<script>
export default {
  data() {
    return {
      percentage: 0,
      customColors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ],
      zhanshi: false
    }
  },
  methods: {
    huoqu(zhanshi, zhi) {
      this.zhanshi = zhanshi
      this.percentage = zhi
    }
  }
}
</script>
<style scoped lang="scss">
.jindu_tiao{
    position: fixed;
    left: 0;
    top:0;
    right:0;
    bottom: 0;
    background-color: rgba(255, 255, 255,0);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    .jindu_tiao_box{
        // // width:30%;
        // margin: 0 auto;

        // margin-top: 200px;
        p{
            font-size: 16px;
            text-align: center;
            color:rgb(92, 182, 255);
            display: flex;
            justify-content: center;
            i{
                // display: block;
                // margin: 0 auto;
                font-size: 56px;
                color:rgb(92, 182, 255);
            }
        }
    }
}
</style>
