<template>
  <div>
  <div class="exportb">
<!-- <div>导出导出</div> -->
      <div class="con">
          <div class="box">
              <!-- <div class="p1"><span @click="dianji_fanhui">返回</span>{{text1}}</div> -->
                 <div class="img"><img :src="require('@img/import/daochu.png')" alt="">{{text2}}</div>
              <p class="p2">{{text3}}</p>
              <!-- <p class="p3" @click="moban_xiazai">导出</p> -->
          </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Exportb',
  props:['leixing'],
  data() {
    return {
      text1: '',
      text2: '',
      text3: '',
      // url:'http://39.103.155.218:8080/hwb_client/V1.0.0/',//服务器2号机
      // url: 'https://www.mlcbr.com:8443/hwb_client/V1.0.0/  ',  
      url:'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/'
      // url: 'https://www.meilibaobiao.com:8080/hwb_client/V1.0.0/'
      // url:'http://192.168.3.5:8080/hwb_client/V1.0.0/'
    }
  },
   computed:{
    ent_id(){
      return this.$store.state.ent_id
    }
  },
  created() {
       if (this.leixing == '菜品') {
        this.text1 = '批量导出菜品信息',
        this.text2 = '导出菜品',
        this.text3 = '法律声明：企业菜品导出服务由餐亿本研发并经授权企业管理员选择开通、使用、管理，相关数据存储于餐亿本专属云存储空间并归属企业所有。餐亿本安全技术已通过相关机构认证，请放心使用。'
      } else if (this.leixing == '人员') {
        this.text1 = '批量导出人员信息',
        this.text2 = '导出人员',
        this.text3 = '法律声明：企业人员导出服务由餐亿本研发并经授权企业管理员选择开通、使用、管理，相关数据存储于餐亿本专属云存储空间并归属企业所有。餐亿本安全技术已通过相关机构认证，请放心使用。'
      } else if (this.leixing == '科目') {
        this.text1 = '批量导出核算科目信息',
        this.text2 = '导出核算科目',
        this.text3 = '法律声明：企业核算科目导出服务由餐亿本研发并经授权企业管理员选择开通、使用、管理，相关数据存储于餐亿本专属云存储空间并归属企业所有。餐亿本安全技术已通过相关机构认证，请放心使用。'
      }
  },
  methods: {
    //   下载多个文件
    multiDownLoad(val) {
      for (let i = 0; i < val.length; i++) {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.style.height = 0
        iframe.src = val[i]
        document.body.appendChild(iframe)
        setTimeout(res => {
          iframe.remove()
        }, 5 * 60 * 1000
        )
      }
    },
    // 模板下载
    moban_xiazai() {
      if (this.leixing == '人员') {
        const url = this.url + 'export_staff?ent_id=' + this.ent_id + '&excel_type=2'
        const data = [url]
        console.log(' data = [url]', data)
        this.multiDownLoad(data)
      } else if (this.leixing == '科目') {
        const url = this.url + 'export_subject?ent_id=' + this.ent_id + '&excel_type=2'
        const data = [url]
        this.multiDownLoad(data)
      } else if (this.leixing == '菜品') {
        const url = this.url + 'export_prod?ent_id=' + this.ent_id + '&excel_type=2'
        const data = [url]
        this.multiDownLoad(data)
      }
    },
    // 返回
    dianji_fanhui() {
      window.history.go(-1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.exportb {
    // background:#E7E8EB;
    .con{
        // background:#E7E8EB;
       
       
        margin: 0 auto;
        min-height: 100px;
        .box{
            background: #fff;
            width:100%;
            height:200px;
            // padding: 0 60px;
            margin: 0 auto;
            .p1{
                height:80px;
                background: #ab7eee;
                padding: 0 60px;
                font-size: 18px;
                color:#fff;
                display: flex;
                align-items: center;
                span{
                    padding: 5px 10px;
                    border:0.01rem solid #ccc;
                    border-radius: 3px;
                    color:#1A2533;
                    background: #fff;
                    margin-right: 20px;
                    cursor: pointer;
                }
            }
            .img{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                color:#4C4A4D;
                margin-top:10px;
                img{
                    height:26px;
                    width:26px;
                    margin-right: 22px;
                }
            }
            .p2{
                font-size: 12px;
                color:#A9A9A9;
                padding: 0 60px;
                margin-top: 95px;
            }
            .p3{
                font-size: 16px;
                color:#888888;
                height:38px;
                width:154px;
                border:1px solid #888888;
                line-height: 38px;
                text-align: center;
                margin: 0 auto;
                margin-top: 50px;
                cursor: pointer;
            }
        }
    }
    @media screen and (max-width: 1200px) {
      .con {
        width: 1200px;
        background:#E7E8EB;
      }
    }
}
@media screen and (max-width: 1200px) {
    .exportb {
        width: 1200px;
        background:#E7E8EB;
    }
}
</style>
